@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	word-break: keep-all;
	font-family: 'SUIT';
}
.opacity-border {
	border-bottom: 1px solid rgba(242, 244, 246, 0.3);
}

.transition-lazy {
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 1000ms;
}

.overflow-header {
	background-color: rgba(255, 255, 255, 0.85);
}

.gradient-number {
	background: linear-gradient(180deg, #2cd167 0%, #3888ff 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.bullet-number {
	display: flex;
	width: 24px;
	height: 24px;
	padding: 3.529px 0px 3.471px 0px;
	justify-content: center;
	align-items: center;
	border-radius: 24px;
	background: #000;
	color: #fff;
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 800;
	line-height: 16.941px; /* 141.176% */
}

@media screen and (min-width: 800px) {
	.card-auto-margin-left {
		margin-left: calc(50% - 442px);
	}
	.card-auto-margin-right {
		margin-right: calc(50% - 442px);
	}
	.event-graident-background-circle2 {
		border-radius: 50%;
		background: linear-gradient(
			90deg,
			#08132b 29.39%,
			rgba(20, 27, 96, 0.6) 82.53%
		);
	}
}
.event-gradient-background {
	background: linear-gradient(270deg, #193a8d 0%, #111e36 51.48%);
}

.event-graident-background-circle1 {
	border-radius: 50%;
	background: linear-gradient(270deg, #1b2f77 0%, #0d243f 69.84%);
}

.event-graident-background-circle2 {
	border-radius: 50%;
	background: linear-gradient(
		90deg,
		#08132b 29.39%,
		rgba(20, 27, 96, 0.6) 82.53%
	);
}

.custom-arrow {
	background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkxheWVyIDIiPgo8ZyBpZD0iY2hldnJvbi1kb3duIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTEyLjAwMDIgMTUuNTAwMkMxMS44Njg2IDE1LjUwMDkgMTEuNzM4MSAxNS40NzU3IDExLjYxNjMgMTUuNDI2QzExLjQ5NDQgMTUuMzc2MiAxMS4zODM2IDE1LjMwMjkgMTEuMjkwMiAxNS4yMTAyTDcuMjkwMTkgMTEuMjEwMkM3LjE5Njk1IDExLjExNjkgNy4xMjI5OSAxMS4wMDYzIDcuMDcyNTMgMTAuODg0NEM3LjAyMjA3IDEwLjc2MjYgNi45OTYwOSAxMC42MzIgNi45OTYwOSAxMC41MDAyQzYuOTk2MDkgMTAuMzY4MyA3LjAyMjA3IDEwLjIzNzggNy4wNzI1MyAxMC4xMTU5QzcuMTIyOTkgOS45OTQxMSA3LjE5Njk1IDkuODgzNDIgNy4yOTAxOSA5Ljc5MDE4QzcuMzgzNDIgOS42OTY5NSA3LjQ5NDExIDkuNjIyOTkgNy42MTU5NCA5LjU3MjUzQzcuNzM3NzYgOS41MjIwNiA3Ljg2ODMzIDkuNDk2MDkgOC4wMDAxOSA5LjQ5NjA5QzguMTMyMDQgOS40OTYwOSA4LjI2MjYxIDkuNTIyMDYgOC4zODQ0NCA5LjU3MjUzQzguNTA2MjYgOS42MjI5OSA4LjYxNjk1IDkuNjk2OTUgOC43MTAxOSA5Ljc5MDE4TDEyLjAwMDIgMTMuMTAwMkwxNS4zMDAyIDkuOTIwMTlDMTUuMzkyMiA5LjgxNzkgMTUuNTA0MyA5LjczNTY5IDE1LjYyOTUgOS42Nzg3QzE1Ljc1NDcgOS42MjE3MSAxNS44OTAzIDkuNTkxMTggMTYuMDI3OSA5LjU4OUMxNi4xNjU0IDkuNTg2ODIgMTYuMzAyIDkuNjEzMDQgMTYuNDI4OSA5LjY2NjAzQzE2LjU1NTkgOS43MTkwMyAxNi42NzA1IDkuNzk3NjQgMTYuNzY1NyA5Ljg5Njk3QzE2Ljg2MDkgOS45OTYyOSAxNi45MzQ2IDEwLjExNDIgMTYuOTgyMSAxMC4yNDMzQzE3LjAyOTcgMTAuMzcyNCAxNy4wNTAxIDEwLjUwOTkgMTcuMDQyIDEwLjY0NzJDMTcuMDM0IDEwLjc4NDYgMTYuOTk3NyAxMC45MTg4IDE2LjkzNTUgMTEuMDQxNEMxNi44NzMyIDExLjE2NDEgMTYuNzg2MyAxMS4yNzI2IDE2LjY4MDIgMTEuMzYwMkwxMi42ODAyIDE1LjIyMDJDMTIuNDk3MyAxNS4zOTY1IDEyLjI1NDIgMTUuNDk2NiAxMi4wMDAyIDE1LjUwMDJaIiBmaWxsPSIjOEI5NUExIi8+CjwvZz4KPC9nPgo8L3N2Zz4K);
	background-position: right 0.5rem center;
	background-repeat: no-repeat;
	background-size: 1.5em 1.5em;
}

/* 맥의 경우 스크롤바가 화면 위를 덮는 overlay형식, 스크롤바가 공간을 차지하지 않는다
   윈도우의 경우 컨텐츠 영역 옆에 붙는 classic형식, 컨텐츠 영역이 스크롤바의 크기만큼 줄어든다
	 윈도우에서 스크롤바가 이쁘지 않아서 customize */
.custom-scroll-bar::-webkit-scrollbar {
    width: 10px;
}
/* background of the scrollbar except button or resizer */
.custom-scroll-bar::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
    background-clip: padding-box;
}
/* scrollbar itself */
.custom-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 10px;
    border: 2px solid transparent;
}
/* set button(top and bottom of the scrollbar) */
.custom-scroll-bar::-webkit-scrollbar-button {
    display:none;
}